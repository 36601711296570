<template>
  <div>
    <div v-if="mode === 'DESIGN'">
      <el-date-picker size="medium" disabled :type="type" :placeholder="$t(placeholder)"></el-date-picker>
    </div>
    <div v-else>
      <el-date-picker v-if="!readerMode" v-model="_value" :value-format="$t(format)" size="medium" clearable
                      :type="type" :placeholder="$t(placeholder)"></el-date-picker>
      <div v-else v-text="value"></div>
    </div>
  </div>
</template>

<script>
import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "DateTime",
  components: {},
  props: {
    value: {
      default: null
    },
    format: {
      type: String,
      default: 'common.yyyyMMddHHmm'
    },
    placeholder: {
      type: String,
      default: 'formItem.datePlaceholder'
    },
    readerMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      switch (this.format) {
        case this.$t('common.yyyy'):
          return 'year';
        case this.$t('common.yyyyMM'):
          return 'month';
        case this.$t('common.yyyyMMdd'):
          return 'date';
        case this.$t('common.yyyyMMddHHmm'):
          return 'datetime';
        default:
          return 'datetime';
      }
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
